//----------------------------------------------
.toppage__faq {
	padding: 60px 0;
	background-color: #00548B;
	overflow: hidden;
	position: relative;

	&::before, &::after{
		content: "";
		display: block;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
		position: absolute;
		z-index: 1;
	}

	&::before{
		width: 120px;
		height: 150px;
		background-image: url(../images/toppage/faq_bg_1.png);
		left: 0;
		top: 0;
		opacity: 0.5;
	}

	&::after{
		width: 300px;
    height: 390px;
    background-image: url(../images/toppage/faq_bg_2.png);
    right: -90px;
    top: 41.5%;
	}

	.inner {
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1130px;
		position: relative;
		z-index: 2;
	}

	.ttl {
		margin-bottom: 33px;
		color: #FFF;
		font-size: 30px;
		font-weight: 700;
		text-align: center;
	}

	.faqItem {
		margin: 18px 0;
		background-color: #FFF;
		border-radius: 6px;
		overflow: hidden;

		dt {
			padding: 12px 42px 12px 42px;
			font-size: 16px;
			font-weight: 500;
			line-height: 1.56;
			position: relative;
			cursor: pointer;

			&::before {
				content: "Q.";
				display: block;
				color: #0072BC;
				font-size: 20px;
				font-weight: 700;
				line-height: 1;
				position: absolute;
				left: 15px;
				top: 20px;
			}

			&::after {
				content: "";
				display: block;
				width: 20px;
				height: 20px;
				background-image: url(../images/common/icon_open3.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: contain;
				position: absolute;
				right: 15px;
				top: 50%;
				transition: all 0.3s ease;
				transform: translateY(-50%);
			}

			&.open{
				color: #0072BC;
				background-color: #f1f6f9;
			}

			&.open {
				&::after {
					background-image: url(../images/common/icon_close2.png);
				}
			}
		}

		dd {
			display: none;
			padding: 15px 15px 15px 42px;
			position: relative;

			&::before {
				content: "A.";
				display: block;
				color: #707070;
				font-size: 20px;
				font-weight: 700;
				line-height: 1;
				position: absolute;
				left: 15px;
				top: 20px;
			}

			&.openAtFirstTimeOnly {
				display: block;
			}
		}
	}

	.link {
		margin-top: 32px;
		text-align: center;

		a {
			margin: 0 auto;
			background: transparent;

			&:hover {
				opacity: 1;
				background-color: #008eeb;
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.toppage__faq {
		padding: 120px 0;

		&::before{
			width: 618px;
			height: 990px;
			left: -200px;
			top: -100px;
		}

		&::after{
			width: 434px;
			height: 976px;
			right: -50px;
			top: 40px;
		}

		.ttl {
			margin-bottom: 60px;
			font-size: 43px;
		}

		.faqItem {
			margin: 20px 0;

			dt {
				padding: 24px 52px 24px 75px;
				font-size: 20px;

				&::before {
					font-size: 30px;
					left: 30px;
					top: 22px;
				}

				&::after {
					right: 30px;
				}

				&:hover {
					color: #0072BC;
					background-color: #f1f6f9;
				}
			}

			dd {
				padding: 24px 24px 24px 75px;
				font-size: 16px;

				&::before {
					font-size: 30px;
					left: 30px;
					top: 24px;
				}
			}
		}

		.link {
			margin-top: 60px;
		}
	}
}

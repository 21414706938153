//----------------------------------------------
.toppage__whatsnew {
	padding: 60px 0 20px;

	.inner {
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1130px;
	}

	.ttl {
		font-size: 30px;
		font-weight: 700;
		text-align: center;
	}

	.cate_topics,
	.cate_maintenance,
	.cate_system {
		margin: 32px 0;

		.cate_ttl {
			padding-left: 10px;
			font-size: 17px;
			font-weight: 500;
			position: relative;

			&::after {
				content: "";
				width: 3px;
				height: 15px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		li {
			margin: 0;
			padding: 15px 0;
			border-bottom: 1px solid #c0dbec;
		}

		.meta{
			display: flex;
			align-items: center;

			.date{
				font-size: 14px;
			}

			.tag{
				display: flex;
				align-items: center;
				margin-left: 16px;
				padding: 0 7px;
				height: 24px;
				font-size: 12px;
				line-height: 24px;
				border: 1px solid #ccc;
				border-radius: 12px;
			}
		}

		.textarea{
			margin-top: 3px;

			a {
				color: #333;
				font-size: 14px;
				line-height: 1.92;
				letter-spacing: 0;
				text-decoration: underline;

				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;

			}
		}

		.link {
			margin-top: 10px;
			text-align: right;

			a {
				display: inline-block;
				width: auto;
				color: #333;
				font-size: 15px;
				font-weight: 500;
				text-decoration: none;

				&:hover{
					color: #0082D6;
					opacity: 1;
				}
			}
		}
	}

	.cate_topics {
		.cate_ttl {
			color: #0072BC;

			&::after {
				border-left: 3px solid #0072BC;
			}
		}
	}

	.cate_maintenance {
		.cate_ttl {
			color: #d11d00;

			&::after {
				border-left: 3px solid #d11d00;
			}
		}
	}

	.cate_system {
		.cate_ttl {
			color: #007c84;

			&::after {
				border-left: 3px solid #007c84;
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.toppage__whatsnew {
		padding: 120px 0 90px;

		.ttl {
			margin-bottom: 22px;
			font-size: 43px;
		}

		.cate_topics,
		.cate_maintenance,
		.cate_system {
			margin: 35px 0;

			.cate_ttl {
				margin-bottom: 10px;
				padding-left: 24px;
				font-size: 20px;

				&::after {
					width: 7px;
					height: 24px;
				}
			}

			li {
				padding: 17px 15px 17px 20px;
			}

			.meta{
				min-width: 185px;

				.date{
					font-size: 16px;
				}

				.tag{
					padding: 0 10px;
					height: 28px;
					font-size: 14px;
					line-height: 28px;
					border-radius: 14px;
				}
			}

			.textarea{
				margin-top: 5px;

				a {
					font-size: 16px;
				}
			}

			.link {
				a {
					letter-spacing: 0.1em;
				}
			}
		}

		.flexBox {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.cate_topics,
		.cate_maintenance {
			width: 45%;
		}

		.cate_system {
			width: 100%;

			li {
				display: flex;
			}
		}

		.cate_topics {
			.cate_ttl {
				&::after {
					border-left: 7px solid #0072BC;
				}
			}
		}

		.cate_maintenance {
			.cate_ttl {
				&::after {
					border-left: 7px solid #d11d00;
				}
			}
		}

		.cate_system {
			.cate_ttl {
				&::after {
					border-left: 7px solid #007c84;
				}
			}
		}
	}
}

//----------------------------------------------
.toppage__about {
  padding: 60px 0;
  background-color: #0072bc;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    position: absolute;
    z-index: 1;
  }

  &::before {
    width: 120px;
    height: 150px;
    background-image: url(../images/toppage/about_bg_1.png);
    left: 0;
    top: 0;
    opacity: 0.5;
  }

  &::after {
    width: 300px;
    height: 390px;
    background-image: url(../images/toppage/about_bg_2.png);
    right: -90px;
    top: 41.5%;
  }

  .inner {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1130px;
    position: relative;
    z-index: 2;
  }

  .ttl {
    margin-bottom: 30px;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  .textarea {
    margin-top: 30px;
    color: #fff;
    text-align: center;
    line-height: 3;
    margin: 0 auto;
  }

  .service_title {
    color: rgb(37, 37, 248);
    font-size: large;
    font-weight: 900;
  }

  .services {
    display: flex;
    flex-flow: column;
    height: auto;
    margin: 0 0 1em;
    width: 100%;
  }

  .service {
    padding: 1em;
    margin-top: 30px;
    background-color: #fff;
    width: 100%;
    height: 180px;
    border-radius: 10px;

    a {
      font-size: smaller;
    }
  }

  .link {
    margin-top: 35px;
    text-align: center;

    a {
      margin: 0 auto;
      background: transparent;

      &:hover {
        opacity: 1;
        background-color: #008eeb;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .toppage__about {
    .img {
      text-align: center;

      img {
        max-width: 208px;
      }
    }
  }

  .service {
    a {
      font-size: inherit;
    }
  }
}
@media screen and (min-width: 992px) {
  .toppage__about {
    padding: 120px 0;

    &::before {
      width: 320px;
      height: 396px;
      left: 0;
      top: 0;
    }

    &::after {
      width: 606px;
      height: 778px;
      right: -130px;
      top: 15.5%;
    }

    .ttl {
      margin-bottom: 30px;
      font-size: 43px;
    }

    .flexBox {
      display: flex;
    }

    .img {
      padding-top: 30px;
      width: 50%;

      img {
        max-width: 480px;
      }
    }

    .textarea {
      padding: 0 0 0 15px;
    }

    .service_title {
      color: rgb(37, 37, 248);
      font-size: large;
      font-weight: 900;
    }

    .services {
      display: flex;
      flex-flow: column;
      height: auto;
      margin: 0 0 1em;
      width: 50%;
    }

    .service {
      padding: 1em;
      margin-top: 30px;
      background-color: #fff;
      width: 100%;
      height: 180px;
      border-radius: 10px;

      a {
        padding-left: 20px;
        font-size: inherit;
      }
    }

    .link {
      margin-top: 54px;
    }
  }
}

@charset "UTF-8";
/************************
トップページ
************************/
.toppage__mv {
  width: 100%;
  background-image: url(../images/toppage/mv_bg.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.toppage__mv img {
  display: block;
}
.toppage__mv__cont {
  padding: 95px 15px 0;
  position: relative;
  z-index: 2;
}
.toppage__mv__textarea span {
  display: block;
  transition: all 0.6s ease;
}
.toppage__mv__textarea .t1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.45;
}
.toppage__mv__textarea .t2 {
  margin-top: 7px;
  color: #0072BC;
  font-size: 29px;
  font-weight: 700;
  line-height: 1.45;
  letter-spacing: 0.08em;
}
.toppage__mv__textarea .t2 small {
  font-size: 23px;
}
.toppage__mv__textarea .t3 {
  margin-top: 5px;
  color: #0072BC;
  font-size: 27px;
  font-weight: 700;
  line-height: 1.45;
  letter-spacing: 0.075em;
}
.toppage__mv__textarea .link {
  margin-top: 30px;
}
.toppage__mv__img01 {
  margin-top: -15px;
  padding: 0 0 0 17px;
  width: 112%;
}
.toppage__mv__img02 {
  margin: -18vw 2% -68px auto;
  width: 74%;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .toppage__mv {
    height: 440px;
    position: relative;
  }
  .toppage__mv__cont {
    display: flex;
    align-items: center;
    padding: 85px 0 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
  }
  .toppage__mv__textarea {
    margin: 0 auto;
    padding: 0 25px;
    width: 100%;
    max-width: 1150px;
  }
  .toppage__mv__textarea .t1 {
    font-size: 18px;
    letter-spacing: 0.05em;
  }
  .toppage__mv__textarea .t2 {
    margin-top: 8px;
    font-size: 30px;
  }
  .toppage__mv__textarea .t2 small {
    font-size: 26px;
  }
  .toppage__mv__textarea .t3 {
    margin-top: 0;
    font-size: 30px;
    letter-spacing: 0.06em;
  }
  .toppage__mv__textarea .link {
    margin-top: 40px;
  }
  .toppage__mv__textarea .link a {
    width: 320px;
    height: 60px;
  }
  .toppage__mv__img01 {
    margin: 0;
    padding: 0;
    width: 500px;
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(58px);
    z-index: 1;
  }
  .toppage__mv__img02 {
    margin: 0;
    width: 310px;
    position: absolute;
    left: 50%;
    bottom: -108px;
    z-index: 2;
    transform: translateX(150px);
  }
}
@media screen and (min-width: 992px) {
  .toppage__mv {
    height: 640px;
  }
  .toppage__mv__cont {
    padding-top: 85px;
  }
  .toppage__mv__textarea .t1 {
    font-size: 20px;
  }
  .toppage__mv__textarea .t2 {
    margin-top: 8px;
    font-size: 40px;
  }
  .toppage__mv__textarea .t2 small {
    font-size: 45px;
  }
  .toppage__mv__textarea .t3 {
    font-size: 40px;
    letter-spacing: 0.06em;
  }
  .toppage__mv__textarea .link {
    margin-top: 40px;
  }
  .toppage__mv__textarea .link a {
    width: 380px;
    height: 60px;
    font-size: 18px;
  }
  .toppage__mv__img01 {
    width: 753px;
    bottom: 200px;
  }
  .toppage__mv__img02 {
    width: 487px;
    bottom: -108px;
    transform: translateX(150px);
  }
}
@media screen and (min-width: 1200px) {
  .toppage__mv__textarea .t1 {
    font-size: 26px;
  }
  .toppage__mv__textarea .t2 {
    font-size: 55px;
  }
  .toppage__mv__textarea .t3 {
    font-size: 55px;
  }
  .toppage__mv__textarea .link {
    margin-top: 40px;
  }
  .toppage__mv__textarea .link a {
    width: 408px;
    height: 72px;
    font-size: 20px;
  }
}
.toppage__information {
  padding: 38px 0 40px;
}
.toppage__information .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
}
.toppage__information .ttl {
  padding: 5px 15px;
  color: #FFF;
  font-size: 13px;
  letter-spacing: 0;
  background-color: #e72929;
}
.toppage__information .list {
  padding: 15px 15px 10px;
  border: 1px solid #e72929;
}
.toppage__information li + li {
  margin-top: 14px;
}
.toppage__information .date {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0;
}
.toppage__information a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  letter-spacing: 0;
  line-height: 1.92;
}

@media screen and (min-width: 768px) {
  .toppage__information .flexBox {
    display: flex;
  }
  .toppage__information .ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
  }
  .toppage__information .list {
    padding: 15px 38px 13px;
    width: calc(100% - 188px);
  }
  .toppage__information li {
    display: flex;
    align-items: center;
  }
  .toppage__information .date {
    width: 120px;
    line-height: 1.4;
    letter-spacing: 0.05em;
  }
  .toppage__information a {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: calc(100% - 120px);
    line-height: 1.4;
  }
}
@media screen and (min-width: 992px) {
  .toppage__information {
    padding: 40px 0;
  }
  .toppage__information .ttl {
    font-size: 16px;
    font-weight: 500;
  }
  .toppage__information .date {
    font-size: 16px;
    font-weight: 400;
  }
  .toppage__information a {
    font-size: 16px;
    font-weight: 400;
  }
}
.toppage__about {
  padding: 60px 0;
  background-color: #0072bc;
  overflow: hidden;
  position: relative;
}
.toppage__about::before, .toppage__about::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  z-index: 1;
}
.toppage__about::before {
  width: 120px;
  height: 150px;
  background-image: url(../images/toppage/about_bg_1.png);
  left: 0;
  top: 0;
  opacity: 0.5;
}
.toppage__about::after {
  width: 300px;
  height: 390px;
  background-image: url(../images/toppage/about_bg_2.png);
  right: -90px;
  top: 41.5%;
}
.toppage__about .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
  position: relative;
  z-index: 2;
}
.toppage__about .ttl {
  margin-bottom: 30px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.toppage__about .textarea {
  margin-top: 30px;
  color: #fff;
  text-align: center;
  line-height: 3;
  margin: 0 auto;
}
.toppage__about .service_title {
  color: rgb(37, 37, 248);
  font-size: large;
  font-weight: 900;
}
.toppage__about .services {
  display: flex;
  flex-flow: column;
  height: auto;
  margin: 0 0 1em;
  width: 100%;
}
.toppage__about .service {
  padding: 1em;
  margin-top: 30px;
  background-color: #fff;
  width: 100%;
  height: 180px;
  border-radius: 10px;
}
.toppage__about .service a {
  font-size: smaller;
}
.toppage__about .link {
  margin-top: 35px;
  text-align: center;
}
.toppage__about .link a {
  margin: 0 auto;
  background: transparent;
}
.toppage__about .link a:hover {
  opacity: 1;
  background-color: #008eeb;
}

@media screen and (min-width: 768px) {
  .toppage__about .img {
    text-align: center;
  }
  .toppage__about .img img {
    max-width: 208px;
  }
  .service a {
    font-size: inherit;
  }
}
@media screen and (min-width: 992px) {
  .toppage__about {
    padding: 120px 0;
  }
  .toppage__about::before {
    width: 320px;
    height: 396px;
    left: 0;
    top: 0;
  }
  .toppage__about::after {
    width: 606px;
    height: 778px;
    right: -130px;
    top: 15.5%;
  }
  .toppage__about .ttl {
    margin-bottom: 30px;
    font-size: 43px;
  }
  .toppage__about .flexBox {
    display: flex;
  }
  .toppage__about .img {
    padding-top: 30px;
    width: 50%;
  }
  .toppage__about .img img {
    max-width: 480px;
  }
  .toppage__about .textarea {
    padding: 0 0 0 15px;
  }
  .toppage__about .service_title {
    color: rgb(37, 37, 248);
    font-size: large;
    font-weight: 900;
  }
  .toppage__about .services {
    display: flex;
    flex-flow: column;
    height: auto;
    margin: 0 0 1em;
    width: 50%;
  }
  .toppage__about .service {
    padding: 1em;
    margin-top: 30px;
    background-color: #fff;
    width: 100%;
    height: 180px;
    border-radius: 10px;
  }
  .toppage__about .service a {
    padding-left: 20px;
    font-size: inherit;
  }
  .toppage__about .link {
    margin-top: 54px;
  }
}
.toppage__process {
  padding: 60px 0;
}
.toppage__process .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
  position: relative;
}
.toppage__process .inner::after {
  content: "";
  display: block;
  width: 60%;
  height: 365px;
  background-color: #f1f6f9;
  position: absolute;
  left: 0;
  top: -60px;
  z-index: -1;
}
.toppage__process .blockTitle {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.toppage__process .img {
  text-align: center;
}
.toppage__process .img img {
  width: 208px;
}
.toppage__process .ttl {
  margin-top: 27px;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.45;
  text-align: center;
}
.toppage__process .txt {
  margin-top: 30px;
  line-height: 1.92;
}
.toppage__process ul {
  margin-top: 24px;
  padding: 0;
}
.toppage__process li {
  margin: 0;
  padding: 0;
}
.toppage__process a {
  display: flex;
  align-items: center;
  padding: 0 45px 0 15px;
  width: 100%;
  height: 56px;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: none;
  border-bottom: 1px solid #c0dbec;
}
.toppage__process a::after {
  right: 20px;
}
.toppage__process a:hover {
  color: #0082d6;
  background-color: #eff9ff;
  opacity: 1;
}
.toppage__process a:hover::after {
  right: 15px;
}

@media screen and (min-width: 992px) {
  .toppage__process {
    padding: 120px 0 60px;
  }
  .toppage__process .inner::after {
    content: "";
    display: block;
    width: 50vw;
    height: 730px;
    background-color: #f1f6f9;
    position: absolute;
    left: auto;
    right: 100%;
    top: -120px;
    transform: translateX(350px);
  }
  .toppage__process .blockTitle {
    margin-bottom: 60px;
    font-size: 43px;
  }
  .toppage__process .flexBox {
    display: flex;
  }
  .toppage__process .img {
    margin-bottom: 180px;
    width: 49%;
    text-align: left;
  }
  .toppage__process .img img {
    width: 80%;
    max-width: 417px;
  }
  .toppage__process .cont {
    width: 51%;
  }
  .toppage__process .ttl {
    margin: 0;
    font-size: 35px;
    text-align: left;
  }
  .toppage__process .txt {
    font-size: 16px;
    line-height: 2.25;
  }
  .toppage__process ul {
    margin-top: 28px;
  }
  .toppage__process a {
    padding: 0 55px 0 20px;
    height: 89px;
    font-size: 20px;
  }
  .toppage__process a::after {
    right: 30px;
  }
  .toppage__process a:hover::after {
    right: 25px;
  }
}
.toppage__system {
  padding: 50px 0 55px;
}
.toppage__system .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
  position: relative;
}
.toppage__system .inner::after {
  content: "";
  display: block;
  width: 60%;
  height: 365px;
  background-color: #f1f6f9;
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
}
.toppage__system .img {
  text-align: center;
}
.toppage__system .img img {
  width: 208px;
}
.toppage__system .ttl {
  margin-top: 27px;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.45;
  text-align: center;
}
.toppage__system .txt {
  margin-top: 30px;
  line-height: 1.92;
}
.toppage__system ul {
  margin-top: 24px;
  padding: 0;
}
.toppage__system li {
  margin: 0;
  padding: 0;
}
.toppage__system a {
  display: flex;
  align-items: center;
  padding: 0 45px 0 15px;
  width: 100%;
  height: 56px;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: none;
  border-bottom: 1px solid #c0dbec;
}
.toppage__system a::after {
  right: 20px;
}
.toppage__system a:hover {
  color: #0082d6;
  background-color: #eff9ff;
  opacity: 1;
}
.toppage__system a:hover::after {
  right: 15px;
}

@media screen and (min-width: 992px) {
  .toppage__system {
    padding: 60px 0 120px;
  }
  .toppage__system .inner::after {
    content: "";
    display: block;
    width: 50vw;
    height: 730px;
    background-color: #f1f6f9;
    position: absolute;
    left: auto;
    left: 100%;
    top: 0;
    transform: translateX(-350px);
  }
  .toppage__system .flexBox {
    display: flex;
    flex-direction: row-reverse;
  }
  .toppage__system .img {
    margin-bottom: 180px;
    padding-top: 120px;
    width: 49%;
    text-align: right;
  }
  .toppage__system .img img {
    width: 80%;
    max-width: 417px;
  }
  .toppage__system .cont {
    width: 51%;
  }
  .toppage__system .ttl {
    margin: 0;
    font-size: 35px;
    text-align: left;
  }
  .toppage__system .txt {
    margin-top: 35px;
    font-size: 16px;
    line-height: 2.25;
  }
  .toppage__system ul {
    margin-top: 24px;
  }
  .toppage__system a {
    padding: 0 55px 0 20px;
    height: 89px;
    font-size: 20px;
  }
  .toppage__system a::after {
    right: 30px;
  }
  .toppage__system a:hover::after {
    right: 25px;
  }
}
.toppage__whatsnew {
  padding: 60px 0 20px;
}
.toppage__whatsnew .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
}
.toppage__whatsnew .ttl {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.toppage__whatsnew .cate_topics,
.toppage__whatsnew .cate_maintenance,
.toppage__whatsnew .cate_system {
  margin: 32px 0;
}
.toppage__whatsnew .cate_topics .cate_ttl,
.toppage__whatsnew .cate_maintenance .cate_ttl,
.toppage__whatsnew .cate_system .cate_ttl {
  padding-left: 10px;
  font-size: 17px;
  font-weight: 500;
  position: relative;
}
.toppage__whatsnew .cate_topics .cate_ttl::after,
.toppage__whatsnew .cate_maintenance .cate_ttl::after,
.toppage__whatsnew .cate_system .cate_ttl::after {
  content: "";
  width: 3px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.toppage__whatsnew .cate_topics li,
.toppage__whatsnew .cate_maintenance li,
.toppage__whatsnew .cate_system li {
  margin: 0;
  padding: 15px 0;
  border-bottom: 1px solid #c0dbec;
}
.toppage__whatsnew .cate_topics .meta,
.toppage__whatsnew .cate_maintenance .meta,
.toppage__whatsnew .cate_system .meta {
  display: flex;
  align-items: center;
}
.toppage__whatsnew .cate_topics .meta .date,
.toppage__whatsnew .cate_maintenance .meta .date,
.toppage__whatsnew .cate_system .meta .date {
  font-size: 14px;
}
.toppage__whatsnew .cate_topics .meta .tag,
.toppage__whatsnew .cate_maintenance .meta .tag,
.toppage__whatsnew .cate_system .meta .tag {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 0 7px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  border: 1px solid #ccc;
  border-radius: 12px;
}
.toppage__whatsnew .cate_topics .textarea,
.toppage__whatsnew .cate_maintenance .textarea,
.toppage__whatsnew .cate_system .textarea {
  margin-top: 3px;
}
.toppage__whatsnew .cate_topics .textarea a,
.toppage__whatsnew .cate_maintenance .textarea a,
.toppage__whatsnew .cate_system .textarea a {
  color: #333;
  font-size: 14px;
  line-height: 1.92;
  letter-spacing: 0;
  text-decoration: underline;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.toppage__whatsnew .cate_topics .link,
.toppage__whatsnew .cate_maintenance .link,
.toppage__whatsnew .cate_system .link {
  margin-top: 10px;
  text-align: right;
}
.toppage__whatsnew .cate_topics .link a,
.toppage__whatsnew .cate_maintenance .link a,
.toppage__whatsnew .cate_system .link a {
  display: inline-block;
  width: auto;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}
.toppage__whatsnew .cate_topics .link a:hover,
.toppage__whatsnew .cate_maintenance .link a:hover,
.toppage__whatsnew .cate_system .link a:hover {
  color: #0082D6;
  opacity: 1;
}
.toppage__whatsnew .cate_topics .cate_ttl {
  color: #0072BC;
}
.toppage__whatsnew .cate_topics .cate_ttl::after {
  border-left: 3px solid #0072BC;
}
.toppage__whatsnew .cate_maintenance .cate_ttl {
  color: #d11d00;
}
.toppage__whatsnew .cate_maintenance .cate_ttl::after {
  border-left: 3px solid #d11d00;
}
.toppage__whatsnew .cate_system .cate_ttl {
  color: #007c84;
}
.toppage__whatsnew .cate_system .cate_ttl::after {
  border-left: 3px solid #007c84;
}

@media screen and (min-width: 992px) {
  .toppage__whatsnew {
    padding: 120px 0 90px;
  }
  .toppage__whatsnew .ttl {
    margin-bottom: 22px;
    font-size: 43px;
  }
  .toppage__whatsnew .cate_topics,
  .toppage__whatsnew .cate_maintenance,
  .toppage__whatsnew .cate_system {
    margin: 35px 0;
  }
  .toppage__whatsnew .cate_topics .cate_ttl,
  .toppage__whatsnew .cate_maintenance .cate_ttl,
  .toppage__whatsnew .cate_system .cate_ttl {
    margin-bottom: 10px;
    padding-left: 24px;
    font-size: 20px;
  }
  .toppage__whatsnew .cate_topics .cate_ttl::after,
  .toppage__whatsnew .cate_maintenance .cate_ttl::after,
  .toppage__whatsnew .cate_system .cate_ttl::after {
    width: 7px;
    height: 24px;
  }
  .toppage__whatsnew .cate_topics li,
  .toppage__whatsnew .cate_maintenance li,
  .toppage__whatsnew .cate_system li {
    padding: 17px 15px 17px 20px;
  }
  .toppage__whatsnew .cate_topics .meta,
  .toppage__whatsnew .cate_maintenance .meta,
  .toppage__whatsnew .cate_system .meta {
    min-width: 185px;
  }
  .toppage__whatsnew .cate_topics .meta .date,
  .toppage__whatsnew .cate_maintenance .meta .date,
  .toppage__whatsnew .cate_system .meta .date {
    font-size: 16px;
  }
  .toppage__whatsnew .cate_topics .meta .tag,
  .toppage__whatsnew .cate_maintenance .meta .tag,
  .toppage__whatsnew .cate_system .meta .tag {
    padding: 0 10px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 14px;
  }
  .toppage__whatsnew .cate_topics .textarea,
  .toppage__whatsnew .cate_maintenance .textarea,
  .toppage__whatsnew .cate_system .textarea {
    margin-top: 5px;
  }
  .toppage__whatsnew .cate_topics .textarea a,
  .toppage__whatsnew .cate_maintenance .textarea a,
  .toppage__whatsnew .cate_system .textarea a {
    font-size: 16px;
  }
  .toppage__whatsnew .cate_topics .link a,
  .toppage__whatsnew .cate_maintenance .link a,
  .toppage__whatsnew .cate_system .link a {
    letter-spacing: 0.1em;
  }
  .toppage__whatsnew .flexBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .toppage__whatsnew .cate_topics,
  .toppage__whatsnew .cate_maintenance {
    width: 45%;
  }
  .toppage__whatsnew .cate_system {
    width: 100%;
  }
  .toppage__whatsnew .cate_system li {
    display: flex;
  }
  .toppage__whatsnew .cate_topics .cate_ttl::after {
    border-left: 7px solid #0072BC;
  }
  .toppage__whatsnew .cate_maintenance .cate_ttl::after {
    border-left: 7px solid #d11d00;
  }
  .toppage__whatsnew .cate_system .cate_ttl::after {
    border-left: 7px solid #007c84;
  }
}
.toppage__faq {
  padding: 60px 0;
  background-color: #00548B;
  overflow: hidden;
  position: relative;
}
.toppage__faq::before, .toppage__faq::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  z-index: 1;
}
.toppage__faq::before {
  width: 120px;
  height: 150px;
  background-image: url(../images/toppage/faq_bg_1.png);
  left: 0;
  top: 0;
  opacity: 0.5;
}
.toppage__faq::after {
  width: 300px;
  height: 390px;
  background-image: url(../images/toppage/faq_bg_2.png);
  right: -90px;
  top: 41.5%;
}
.toppage__faq .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
  position: relative;
  z-index: 2;
}
.toppage__faq .ttl {
  margin-bottom: 33px;
  color: #FFF;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.toppage__faq .faqItem {
  margin: 18px 0;
  background-color: #FFF;
  border-radius: 6px;
  overflow: hidden;
}
.toppage__faq .faqItem dt {
  padding: 12px 42px 12px 42px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  position: relative;
  cursor: pointer;
}
.toppage__faq .faqItem dt::before {
  content: "Q.";
  display: block;
  color: #0072BC;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  left: 15px;
  top: 20px;
}
.toppage__faq .faqItem dt::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../images/common/icon_open3.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  right: 15px;
  top: 50%;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}
.toppage__faq .faqItem dt.open {
  color: #0072BC;
  background-color: #f1f6f9;
}
.toppage__faq .faqItem dt.open::after {
  background-image: url(../images/common/icon_close2.png);
}
.toppage__faq .faqItem dd {
  display: none;
  padding: 15px 15px 15px 42px;
  position: relative;
}
.toppage__faq .faqItem dd::before {
  content: "A.";
  display: block;
  color: #707070;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  left: 15px;
  top: 20px;
}
.toppage__faq .faqItem dd.openAtFirstTimeOnly {
  display: block;
}
.toppage__faq .link {
  margin-top: 32px;
  text-align: center;
}
.toppage__faq .link a {
  margin: 0 auto;
  background: transparent;
}
.toppage__faq .link a:hover {
  opacity: 1;
  background-color: #008eeb;
}

@media screen and (min-width: 992px) {
  .toppage__faq {
    padding: 120px 0;
  }
  .toppage__faq::before {
    width: 618px;
    height: 990px;
    left: -200px;
    top: -100px;
  }
  .toppage__faq::after {
    width: 434px;
    height: 976px;
    right: -50px;
    top: 40px;
  }
  .toppage__faq .ttl {
    margin-bottom: 60px;
    font-size: 43px;
  }
  .toppage__faq .faqItem {
    margin: 20px 0;
  }
  .toppage__faq .faqItem dt {
    padding: 24px 52px 24px 75px;
    font-size: 20px;
  }
  .toppage__faq .faqItem dt::before {
    font-size: 30px;
    left: 30px;
    top: 22px;
  }
  .toppage__faq .faqItem dt::after {
    right: 30px;
  }
  .toppage__faq .faqItem dt:hover {
    color: #0072BC;
    background-color: #f1f6f9;
  }
  .toppage__faq .faqItem dd {
    padding: 24px 24px 24px 75px;
    font-size: 16px;
  }
  .toppage__faq .faqItem dd::before {
    font-size: 30px;
    left: 30px;
    top: 24px;
  }
  .toppage__faq .link {
    margin-top: 60px;
  }
}
.toppage__banner {
  padding: 40px 0;
  background-color: #0072BC;
}
.toppage__banner .inner {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1130px;
}
.toppage__banner .banner {
  margin: 0 auto;
  max-width: 345px;
  background-color: #FFF;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.toppage__banner .banner::before, .toppage__banner .banner::after {
  content: "";
  display: block;
  width: 15px;
  height: 100%;
  position: absolute;
  top: 0;
}
.toppage__banner .banner::before {
  background-color: #CEEBFF;
  left: 0;
}
.toppage__banner .banner::after {
  background-color: #E9F7FF;
  left: 15px;
}
.toppage__banner .banner a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
  position: relative;
}
.toppage__banner .banner a::after {
  content: "";
  display: block;
  width: 20px;
  height: 14px;
  background-image: url(../images/common/icon_blank_blue.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.toppage__banner .banner .textarea {
  padding: 12px 10px;
  width: calc(100% - 60px);
}
.toppage__banner .banner .ttl {
  margin: 0 auto;
  padding: 5px 0;
  max-width: 260px;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  background-color: #F1F6F9;
  border-radius: 50px;
}
.toppage__banner .banner .ttl span {
  margin-right: 5px;
  color: #00548B;
  font-size: 13px;
  font-weight: 700;
}
.toppage__banner .banner .txt {
  margin-top: 10px;
  color: #0082D6;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .toppage__banner {
    padding: 70px 0;
  }
  .toppage__banner .banner {
    margin: 0 auto;
    width: 100%;
    max-width: 655px;
  }
  .toppage__banner .banner::before, .toppage__banner .banner::after {
    width: 30px;
  }
  .toppage__banner .banner::after {
    width: 25px;
    left: 30px;
  }
  .toppage__banner .banner a::after {
    width: 26px;
    height: 18px;
    right: 30px;
  }
  .toppage__banner .banner .textarea {
    padding: 28px 10px;
  }
  .toppage__banner .banner .ttl {
    max-width: 482px;
    font-size: 20px;
    letter-spacing: 0.05em;
  }
  .toppage__banner .banner .ttl span {
    font-size: 24px;
  }
  .toppage__banner .banner .txt {
    font-size: 30px;
    letter-spacing: 0.05em;
  }
}
//----------------------------------------------
.toppage__banner {
	padding: 40px 0;
	background-color: #0072BC;

	.inner {
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1130px;
	}

	.banner {
		margin: 0 auto;
		max-width: 345px;
		background-color: #FFF;
		border-radius: 6px;
		overflow: hidden;
		position: relative;

		&::before, &::after{
			content: "";
			display: block;
			width: 15px;
			height: 100%;
			position: absolute;
			top: 0;
		}

		&::before{
			background-color: #CEEBFF;
			left: 0;
		}

		&::after{
			background-color: #E9F7FF;
			left: 15px;
		}
		
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #333;
			text-decoration: none;
			position: relative;

			&::after {
				content: "";
				display: block;
				width: 20px;
				height: 14px;
				background-image: url(../images/common/icon_blank_blue.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: contain;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.textarea {
			padding: 12px 10px;
			width: calc(100% - 60px);
		}

		.ttl {
			margin: 0 auto;
			padding: 5px 0;
			max-width: 260px;
			font-size: 11px;
			font-weight: 400;
			text-align: center;
			background-color: #F1F6F9;
			border-radius: 50px;

			span{
				margin-right: 5px;
				color: #00548B;
				font-size: 13px;
				font-weight: 700;
			}
		}

		.txt {
			margin-top: 10px;
			color: #0082D6;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
			line-height: 1;
		}
	}
}
@media screen and (min-width: 768px) {
	.toppage__banner {
		padding: 70px 0;

		.banner {
			margin: 0 auto;
			width: 100%;
			max-width: 655px;

			&::before, &::after{
				width: 30px;
			}

			&::after{
				width: 25px;
				left: 30px;
			}

			a {

				&::after {
					width: 26px;
					height: 18px;
					right: 30px;
				}
			}

			.textarea {
				padding: 28px 10px;
			}

			.ttl {
				max-width: 482px;
				font-size: 20px;
				letter-spacing: 0.05em;

				span{
					font-size: 24px;
				}
			}

			.txt {
				font-size: 30px;
				letter-spacing: 0.05em;
			}
		}
	}
}

//----------------------------------------------
.toppage__mv {
	width: 100%;
	background-image: url(../images/toppage/mv_bg.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	overflow: hidden;

	img {
		display: block;
	}

	&__cont {
		padding: 95px 15px 0;
		position: relative;
    z-index: 2;
	}

	&__textarea {
		span{
			display: block;
			transition: all 0.6s ease;
		}

		.t1 {
			font-size: 14px;
			font-weight: 700;
			line-height: 1.45;
		}

		.t2 {
			margin-top: 7px;
			color: #0072BC;
			font-size: 29px;
			font-weight: 700;
			line-height: 1.45;
			letter-spacing: 0.08em;

			small {
				font-size: 23px;
			}
		}

		.t3 {
			margin-top: 5px;
			color: #0072BC;
			font-size: 27px;
			font-weight: 700;
			line-height: 1.45;
			letter-spacing: 0.075em;
		}

		.link {
			margin-top: 30px;
		}
	}

	&__img01{
		margin-top: -15px;
    padding: 0 0 0 17px;
    width: 112%;
	}

	&__img02{
		margin: -18vw 2% -68px auto;
    width: 74%;
    position: relative;
    z-index: 2;
	}
}
@media screen and (min-width: 768px) {
	.toppage__mv {
		height: 440px;
		position: relative;

		&__cont {
			display: flex;
			align-items: center;
			padding: 85px 0 0;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 3;
		}

		&__textarea {
			margin: 0 auto;
			padding: 0 25px;
			width: 100%;
			max-width: 1150px;

			.t1 {
				font-size: 18px;
				letter-spacing: 0.05em;
			}

			.t2 {
				margin-top: 8px;
				font-size: 30px;

				small {
					font-size: 26px;
				}
			}

			.t3 {
				margin-top: 0;
				font-size: 30px;
				letter-spacing: 0.06em;
			}

			.link {
				margin-top: 40px;

				a {
					width: 320px;
					height: 60px;
				}
			}
		}

		&__img01{
			margin: 0;
			padding: 0;
			width: 500px;
			position: absolute;
			left: 50%;
			bottom: 80px;
			transform: translateX(58px);
			z-index: 1;
		}

		&__img02{
			margin: 0;
			width: 310px;
			position: absolute;
			left: 50%;
			bottom: -108px;
			z-index: 2;
			transform: translateX(150px);
		}
	}
}
@media screen and (min-width: 992px) {
	.toppage__mv {
		height: 640px;

		&__cont {
			padding-top: 85px;
		}

		&__textarea {

			.t1 {
				font-size: 20px;
			}

			.t2 {
				margin-top: 8px;
				font-size: 40px;

				small {
					font-size: 45px;
				}
			}

			.t3 {
				font-size: 40px;
				letter-spacing: 0.06em;
			}

			.link {
				margin-top: 40px;

				a {
					width: 380px;
					height: 60px;
					font-size: 18px;
				}
			}
		}

		&__img01{
			width: 753px;
			bottom: 200px;
		}

		&__img02{
			width: 487px;
			bottom: -108px;
			transform: translateX(150px);
		}
	}
}
@media screen and (min-width: 1200px) {
	.toppage__mv {

		&__textarea {

			.t1 {
				font-size: 26px;
			}

			.t2 {
				font-size: 55px;
			}

			.t3 {
				font-size: 55px;
			}

			.link {
				margin-top: 40px;

				a {
					width: 408px;
					height: 72px;
					font-size: 20px;
				}
			}
		}
	}
}


//----------------------------------------------
.toppage__information {
	padding: 38px 0 40px;

	.inner {
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1130px;
	}

	.ttl {
		padding: 5px 15px;
		color: #FFF;
		font-size: 13px;
		letter-spacing: 0;
		background-color: #e72929;
	}

	.list {
		padding: 15px 15px 10px;
		border: 1px solid #e72929;
	}

	li + li {
		margin-top: 14px;
	}

	.date {
		display: block;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.25;
		letter-spacing: 0;
	}

	a {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;

		color: #333;
		font-size: 13px;
		font-weight: 400;
		text-decoration: underline;
		letter-spacing: 0;
		line-height: 1.92;
	}
}
@media screen and (min-width: 768px) {
	.toppage__information {
		.flexBox {
			display: flex;
		}

		.ttl {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 188px;
		}

		.list {
			padding: 15px 38px 13px;
			width: calc(100% - 188px);
		}

		li {
			display: flex;
			align-items: center;
		}

		.date {
			width: 120px;
			line-height: 1.4;
			letter-spacing: 0.05em;
		}

		a {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;

			width: calc(100% - 120px);
			line-height: 1.4;
		}
	}
}
@media screen and (min-width: 992px) {
	.toppage__information {
		padding: 40px 0;

		.ttl {
			font-size: 16px;
			font-weight: 500;
		}

		.date {
			font-size: 16px;
			font-weight: 400;
		}

		a {
			font-size: 16px;
			font-weight: 400;
		}
	}
}

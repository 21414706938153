//----------------------------------------------
.toppage__process {
	padding: 60px 0;

	.inner {
		margin: 0 auto;
		padding: 0 15px;
		max-width: 1130px;
		position: relative;

		&::after {
			content: "";
			display: block;
			width: 60%;
			height: 365px;
			background-color: #f1f6f9;
			position: absolute;
			left: 0;
			top: -60px;
			z-index: -1;
		}
	}

	.blockTitle{
		margin-bottom: 30px;
		font-size: 30px;
		font-weight: 700;
		text-align: center;
	}

	.img {
		text-align: center;

		img {
			width: 208px;
		}
	}

	.ttl {
		margin-top: 27px;
		font-size: 23px;
		font-weight: 700;
		line-height: 1.45;
		text-align: center;
	}

	.txt {
		margin-top: 30px;
		line-height: 1.92;
	}

	ul {
		margin-top: 24px;
		padding: 0;
	}

	li {
		margin: 0;
		padding: 0;
	}

	a {
		display: flex;
		align-items: center;
		padding: 0 45px 0 15px;
		width: 100%;
		height: 56px;
		color: #333;
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 0;
		text-decoration: none;
		border-bottom: 1px solid #c0dbec;

		&::after {
			right: 20px;
		}

		&:hover {
			color: #0082d6;
			background-color: #eff9ff;
			opacity: 1;

			&::after {
				right: 15px;
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.toppage__process {
		padding: 120px 0 60px;

		.inner {
			&::after {
				content: "";
				display: block;
				width: 50vw;
				height: 730px;
				background-color: #f1f6f9;
				position: absolute;
				left: auto;
				right: 100%;
				top: -120px;
				transform: translateX(350px);
			}
		}

		.blockTitle{
			margin-bottom: 60px;
			font-size: 43px;
		}

		.flexBox {
			display: flex;
		}

		.img {
			margin-bottom: 180px;
			width: 49%;
			text-align: left;

			img {
				width: 80%;
				max-width: 417px;
			}
		}

		.cont {
			width: 51%;
		}

		.ttl {
			margin: 0;
			font-size: 35px;
			text-align: left;
		}

		.txt {
			font-size: 16px;
			line-height: 2.25;
		}

		ul {
			margin-top: 28px;
		}

		a {
			padding: 0 55px 0 20px;
			height: 89px;
			font-size: 20px;

			&::after {
				right: 30px;
			}

			&:hover {
				&::after {
					right: 25px;
				}
			}
		}
	}
}
